import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { StyledButton, StyledForm } from './styles';
import { useGeolocation } from '@/hooks';
import { DEFAULT_LOCALE } from '@/utils';
import TextInput from '@grimme/ui-components/text-input';
import Select from '@grimme/ui-components/select';
import { countriesList } from '../service-appointment-section/dialog/forms/utils';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

export interface LocationSearchFormProps {
  action?: string;
  countrySelectLabel: string;
  submitLabel: string;
  zipCodeTextfieldLabel: string;
}

export function LocationSearchForm(props: LocationSearchFormProps) {
  const { action, countrySelectLabel, submitLabel, zipCodeTextfieldLabel } =
    props;
  const geolocation = useGeolocation();
  const insights = useAppInsightsContext();
  const [formData, setFormData] = useState({
    countryCode: geolocation.countryCode || DEFAULT_LOCALE,
    zipCode: '',
  });

  const handleOnSubmit = () => {
    insights?.trackEvent({
      name: 'Search Partner',
      properties: {
        country: formData.countryCode,
        zipCode: formData.zipCode,
      },
    });
  };

  const setFormValue = (key: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <StyledForm
      action={action && action}
      onSubmit={handleOnSubmit}
      title="location-search-form"
      className="w-full gap-4 flex flex-col"
    >
      <div className="w-full flex-col flex md:flex-row gap-4">
        <Select
          className="w-full md:max-w-1/2"
          label={countrySelectLabel}
          name="countryCode"
          onValueChange={(e: string) => setFormValue('countryCode', e)}
          options={countriesList}
          size="sm"
          value={formData.countryCode}
        />
        <TextInput
          className="w-full md:max-w-1/2"
          label={zipCodeTextfieldLabel}
          name="zipCode"
          onChange={(e) => setFormValue('zipCode', e.target.value)}
          size="sm"
          value={formData.zipCode}
        />
      </div>
      <StyledButton
        className="w-full md:w-fit"
        onClick={handleOnSubmit}
        type="submit"
        variant="primary"
      >
        {submitLabel}
        <FontAwesomeIcon className="ml-1" icon={faAngleRight} />
      </StyledButton>
    </StyledForm>
  );
}
