export const GRIMME_STATIC_IMAGES_URL = 'https://static.grimme.com/files';
export const PLACEHOLDER_IMAGE = '/assets/img/placeholder-product-image.jpg';

// Assign the appropriate configuration based on the app environment
export const IMG_CONFIG = {
  banner: {
    quality: 85,
    width: 1920,
  },
  default: {
    quality: 70,
    width: 1280,
  },
  thumbnail: {
    quality: 90,
    width: 700,
  },
};
