import { PartnerCard } from './partner-card';
import { LandingPageData } from '@/lib/butter/butter-types';
import Separator from '@grimme/ui-components/separator';
import { useTranslation } from 'react-i18next';
import { LocationSearchForm } from '../location-search-form/location-search-form-index';
import Container from '@grimme/ui-components/container';

export const PartnersSection = (props: LandingPageData['grimme_partners']) => {
  const {
    button,
    description,
    label,
    partner_button: partnerButton,
    search_label: searchLabel,
    search_description: searchDescription,
    title,
  } = props;
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  return (
    <Container className="flex flex-col md:flex-row gap-12 md:gap-24">
      <div className="md:mt-20">
        <p className="g-typography-h6">{label}</p>
        <h2 className="mt-4">{title}</h2>
        <p className="pt-4">{description}</p>
        <Separator className="!w-10 !h-1 mb-1 mt-2" />
        <p className="mt-4 g-p-h6">{searchLabel}</p>
        <p className="mt-6 g-p-h">{searchDescription}</p>
        <LocationSearchForm
          action={`${locale}/partners/`}
          countrySelectLabel={t('myGRIMME_website_partners_country', 'Country')}
          submitLabel={button}
          zipCodeTextfieldLabel={t(
            'myGRIMME_website_partners_zip_code',
            'Zip Code',
          )}
        />
      </div>
      <PartnerCard partnerButton={partnerButton} />
    </Container>
  );
};
