'use client';
import { environment } from '@/environment';
import { TProducts } from '@/lib/products';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Slider } from '@grimme/components';
import Button from '@grimme/ui-components/button';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import Link from 'next/link';
import { useState } from 'react';
import {
  filestack,
  GRIMME_STATIC_IMAGES_URL,
  IMG_CONFIG,
  PLACEHOLDER_IMAGE,
} from '../../lib/filestack';
import { Tabs } from './tabs';
import { useParams } from 'next/navigation';
import Container from '@grimme/ui-components/container';

export const ProductDetailsPreview = (props: {
  button_label: string;
  button_link: string;
  productsDetails: {
    category: string;
    products: TProducts;
  }[];
}) => {
  const { button_label, button_link, productsDetails } = props;
  const params = useParams();

  const insights = useAppInsightsContext();

  const [selectedCategory, setSelectCategory] = useState<number>(0);

  const handleOnClickSeeMore = () => {
    insights?.trackEvent({
      name: 'Discover all Machines',
      properties: {
        category: productsDetails[selectedCategory].category,
      },
    });
  };

  return (
    <Container className="flex flex-col justify-between items-center">
      <Tabs
        categories={productsDetails.map((p) => ({ name: p.category }))}
        setValue={setSelectCategory}
        value={selectedCategory}
      />
      {productsDetails.map(
        (detail, index) =>
          selectedCategory === index && (
            <div className="max-w-full" key={detail.category}>
              <Slider>
                {detail.products.map((product) => (
                  <Slider.Slide
                    data={{
                      features: product.subtitle,
                      href: `${environment.onlineSalesUrl}/${params.locale}/p/${product.slug}`,
                      id: product.id,
                      image:
                        filestack.optimizeImage(
                          `${GRIMME_STATIC_IMAGES_URL}/${product.thumbnail}`,
                          IMG_CONFIG.thumbnail,
                        ) || PLACEHOLDER_IMAGE,
                      name: product.title,
                      type: product.category,
                    }}
                    key={product.id}
                    type="product"
                  />
                ))}
              </Slider>
            </div>
          ),
      )}
      <div className="w-full flex items-start md:mt-2">
        <Button asChild onClick={handleOnClickSeeMore} variant="outline">
          <Link href={button_link}>
            {button_label}
            <FontAwesomeIcon className="ml-1" icon={faAngleRight} />
          </Link>
        </Button>
      </div>
    </Container>
  );
};
