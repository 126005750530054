'use client';
import Link from 'next/link';
import { useContactsPerRegion } from '@/ui/grimme-data-per-region/use-contacts-per-region';
import Card from '@grimme/ui-components/card';
import { useConsent, UserCentricsServiceId, ConsentState } from '@grimme/gdap';
import Button from '@grimme/ui-components/button';
import Image from 'next/image';
import { SingleMarkerMap } from '../single-marker/single-marker';

export const LATITUDE_CORRECTION_FACTOR_MOBILE = 0.005;
export const LATITUDE_CORRECTION_FACTOR_DESKTOP = 0.006;

export type PartnerCardProps = {
  partnerButton: string;
};

export const PartnerCard = (props: PartnerCardProps) => {
  const { partnerButton } = props;
  const contactsPerRegion = useContactsPerRegion();
  const localRepresentation = contactsPerRegion?.local_representation;

  const isGoogleMapsConsentGiven = useConsent(
    UserCentricsServiceId.GOOGLE_MAPS,
  );

  const googleConsentIsGiven =
    isGoogleMapsConsentGiven === ConsentState.GRANTED;

  return (
    <div className="relative w-full">
      <div className="relative z-0 h-[460px] md:h-[560px]  md:max-w-[432px] rounded-md overflow-hidden">
        {!googleConsentIsGiven && (
          <div className="absolute inset-0 bg-grey-500/50 backdrop-blur-sm z-10" />
        )}
        {!googleConsentIsGiven && (
          <Image
            alt=""
            className="object-cover w-full h-full z-0"
            height={759}
            src="/assets/img/map-placeholder-for-consent.png"
            width={1132}
          />
        )}
        {googleConsentIsGiven && (
          <SingleMarkerMap
            hasOverlayingCard
            address={localRepresentation?.address}
          />
        )}
        <Card className="absolute z-10 bottom-2 left-2 right-2 p-6 md:bottom-4 md:right-4 md:left-4">
          <p className="g-typography-h5">{localRepresentation?.name}</p>
          <div className="py-4">
            <p className="g-typography-body1">{localRepresentation?.address}</p>
            <Link
              className="block font-semibold hover:text-primary"
              href={`tel:${localRepresentation?.phone}`}
            >
              {localRepresentation?.phone}
            </Link>
            <Link
              className="block font-semibold hover:text-primary underline"
              href={`mailto:${localRepresentation?.email}`}
            >
              {localRepresentation?.email}
            </Link>
          </div>
          <Link passHref href="/partners">
            <Button className="w-full sm:w-fit" variant="outline">
              {partnerButton}
            </Button>
          </Link>
        </Card>
      </div>
    </div>
  );
};
