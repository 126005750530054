'use client';

import {
  BlogPostList,
  LandingPageData,
  PartnersPageData,
} from '@/lib/butter/butter-types';
import { TProducts } from '@/lib/products';
import { ContactSection } from '@/ui/contact-section/contact-section';
import { DigitalSection } from '@/ui/digital-section/digital-section';
import { Hero } from '@/ui/hero';
import { NewsSection } from '@/ui/news-section/news-section';
import { PartnersSection } from '@/ui/partners-section/partners-section';
import { ProductDetailsPreview } from '@/ui/product-details-preview/product-details-preview';
import { parseCtaSectionButtons } from '@/utils';
import Separator from '@grimme/ui-components/separator';
import Link from 'next/link';
import Button from '@grimme/ui-components/button';
import Image from 'next/image';
import Container from '@grimme/ui-components/container';

type HomeClientPageProps = {
  landingPageData: { fields: LandingPageData };
  newsFeed: BlogPostList;
  partnersData: { fields: PartnersPageData };
  productsDetails: {
    category: string;
    products: TProducts;
  }[];
};

export default function HomeClientPage(props: HomeClientPageProps) {
  const { landingPageData, productsDetails, newsFeed } = props;

  //Buttons for the two CTA sections
  const firstCtaButtons = parseCtaSectionButtons(
    (landingPageData.fields.ctas_section &&
      landingPageData.fields.ctas_section.buttons) ||
      [],
  );

  const secondCtaButtons = parseCtaSectionButtons(
    (landingPageData.fields.grimme_group &&
      landingPageData.fields.grimme_group.buttons) ||
      [],
  );

  return (
    <>
      <Hero {...landingPageData.fields.hero_v2} />
      <div className="py-6">
        <Container className="text-center my-20">
          <h2 className="mb-2">
            {landingPageData.fields.seo_section_components_library.title}
          </h2>
          <p className="mb-4">
            {landingPageData.fields.seo_section_components_library.subtitle}
          </p>
          <Separator className="max-w-10 mx-auto" thickness="thicker" />
        </Container>
      </div>

      <div className="py-6">
        <ProductDetailsPreview
          productsDetails={productsDetails}
          {...landingPageData.fields.product_details_page_preview}
        />
      </div>

      <DigitalSection {...landingPageData.fields.grimme_digital} />

      {/* This is the unwrapped CTA section component. If we introduce it anywhere else we should create a wrapper and use it here and below */}
      <div className="w-full py-32 px-6 relative text-white text-center grid place-content-center">
        <div className="absolute inset-0 opacity-60 bg-grey-500 z-20" />
        <div className="absolute top-0 left-0 right-0 bottom-0">
          <Image
            fill
            alt={landingPageData.fields.ctas_section.title}
            objectFit="cover"
            src={landingPageData.fields.ctas_section.background_image}
          />
        </div>
        <div className="relative max-w-[800px] flex flex-col gap-4 z-30">
          <span className="g-typography-h2">
            {landingPageData.fields.ctas_section.title}
          </span>
          <span className="g-typography-body1">
            {landingPageData.fields.ctas_section.description}
          </span>
          <div className="flex flex-wrap justify-center gap-4">
            {firstCtaButtons.map((button) => (
              <Button key={button.id} variant="outline-negative">
                <Link href={button.props.href as string}>{button.label}</Link>
              </Button>
            ))}
          </div>
        </div>
      </div>

      <div className="py-14">
        <NewsSection {...landingPageData.fields.news} feed={newsFeed.data} />
      </div>

      <div className="py-14">
        <PartnersSection {...landingPageData.fields.grimme_partners} />
      </div>

      <div className="w-full py-32 px-6 relative text-white text-center grid place-content-center">
        <div className="absolute inset-0 opacity-60 bg-grey-500 z-20" />
        <div className="absolute top-0 left-0 right-0 bottom-0">
          <Image
            fill
            alt={landingPageData.fields.grimme_group.title}
            objectFit="cover"
            src={landingPageData.fields.grimme_group.background_image}
          />
        </div>
        <div className="relative max-w-[800px] flex flex-col gap-4 z-30">
          <span className="g-typography-h2">
            {landingPageData.fields.grimme_group.title}
          </span>
          <span className="g-typography-body1">
            {landingPageData.fields.grimme_group.description}
          </span>
          <div className="flex flex-wrap justify-center gap-4">
            {secondCtaButtons.map((button) => (
              <Button key={button.id} variant="outline-negative">
                <Link href={button.props.href as string}>{button.label}</Link>
              </Button>
            ))}
          </div>
        </div>
      </div>

      <div className="py-14">
        <ContactSection {...landingPageData.fields.contact_section} />
      </div>
    </>
  );
}
