import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@grimme/ui-components/button';
import Link from 'next/link';
import { LandingPageData } from '@/lib/butter/butter-types';
import Container from '@grimme/ui-components/container';
import { ButterImageV2 } from '../butter-image-v2';

export function DigitalSection(props: LandingPageData['grimme_digital']) {
  const {
    label,
    headline,
    description,
    secondary_button_text,
    secondary_button_url,
    primary_button_text,
    primary_button_url,
    left_image,
    left_image_alt,
    left_title,
    left_description,
    right_image,
    right_image_alt,
    right_title,
    right_description,
  } = props;

  return (
    <div className="py-14 bg-white">
      <Container>
        <div className="">
          <p className="g-typography-h6">{label}</p>
          <h1 className="mt-4 g-typography-h2 max-w-full sm:max-w-[80%]">
            {headline}
          </h1>
          <p className="mt-4 g-typography-body1 max-w-full sm:max-w-[80%]">
            {description}
          </p>
          <div className="gap-4 flex mt-8 flex-col sm:flex-row">
            <Button asChild className="w-full sm:w-fit" variant="outline">
              <Link href={secondary_button_url}>
                {secondary_button_text}
                <FontAwesomeIcon className="ml-1" icon={faAngleRight} />
              </Link>
            </Button>
            <Button asChild className="w-full sm:w-fit" variant="primary">
              <Link href={primary_button_url}>
                {primary_button_text}
                <FontAwesomeIcon className="ml-1" icon={faAngleRight} />
              </Link>
            </Button>
          </div>
        </div>
        <div className="my-6 flex flex-col sm:flex-row gap-12">
          <div className="w-full">
            <div className="h-64 md:h-96 relative">
              <ButterImageV2
                fill
                alt={left_image_alt}
                className="object-contain"
                src={left_image}
              />
            </div>
            <h2 className="mt-6 g-typography-h3">{left_title}</h2>
            <p className="mt-4 g-typography-body1">{description}</p>
          </div>
          <div className="w-full">
            <div className="h-64 md:h-96 relative">
              <ButterImageV2
                fill
                alt={right_image_alt}
                className="object-contain"
                src={right_image}
              />
            </div>
            <h2 className="mt-6 g-typography-h3">{right_title}</h2>
            <p className="mt-4 g-typography-body1">{right_description}</p>
          </div>
        </div>
      </Container>
    </div>
  );
}
