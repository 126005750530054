import { environment } from '@/environment';
import { IMG_CONFIG } from './constants';
export class Filestack {
  /**
   * Optimized combination for compressing the product images gallery
   * without losing quality. Every time quality and width value is changed,
   * Filestack counts it as new transformation, what might affect our quota.
   * Be aware of it when playing with these values.
   */
  private DEFAULT_IMAGE_CACHE_DURATION = 60 * 60 * 24 * 30 * 12; // 12 months

  /**
   * product domains are whitelisted in the Filestack dashboard
   * to ensure the security of the api key that is exposed here
   */
  private FILESTACK_BASE_URL = 'https://cdn.filestackcontent.com';

  private getTransformationUrl = ({
    quality,
    width,
  }: {
    quality: number;
    width: number;
  }) => {
    const transformationPath = `cache=expiry:${this.DEFAULT_IMAGE_CACHE_DURATION}/resize=width:${width}/output=format:webp,quality:${quality}`;

    return `${this.FILESTACK_BASE_URL}/${environment.filestackApiKey}/${transformationPath}`;
  };

  optimizeImage = (
    url: string | undefined,
    config: {
      quality: number;
      width: number;
    } = IMG_CONFIG.default,
  ) => {
    if (!url) return undefined;

    return `${this.getTransformationUrl(config)}/${url}`;
  };
}

export const filestack = new Filestack();
