import { BlogPostList, LandingPageData } from '@/lib/butter/butter-types';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Teaser, TeaserCard } from '@grimme/components';
import Link from 'next/link';
import { useState } from 'react';
import Button from '@grimme/ui-components/button';
import { useTranslation } from 'react-i18next';
import Container from '@grimme/ui-components/container';
import { ButterImageV2 } from '../butter-image-v2';

export function NewsSection(
  data: LandingPageData['news'] & { feed: BlogPostList['data'] },
) {
  const { title, description, button_label, button_url, feed } = data;
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  return (
    <section>
      <Container>
        <h1 className="g-typography-h2">{title}</h1>
        <p className="g-typography-body1 mt-4">{description}</p>
        <Button asChild className="mt-6 w-full sm:w-fit" variant="primary">
          <Link href={button_url}>
            {button_label}
            <FontAwesomeIcon className="ml-1" icon={faAngleRight} />
          </Link>
        </Button>
      </Container>
      <div className="mt-10">
        <Teaser>
          {feed.map((post, index) => (
            <TeaserCard
              button_label={t(
                'myGRIMME_website_news_section_read_post_button',
                'Mehr Lesen',
              )}
              button_link={`/news/${post.slug}`}
              description={post.summary}
              expanded={expandedIndex === index}
              image={
                <ButterImageV2
                  fill
                  alt={post.featured_image_alt}
                  className="object-cover"
                  src={post.featured_image ?? ''}
                />
              }
              key={index}
              onClick={(isExpanded) =>
                setExpandedIndex(isExpanded ? null : index)
              }
              title={post.title}
            />
          ))}
        </Teaser>
      </div>
    </section>
  );
}
